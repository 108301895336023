:root{
    --factor1: 21.5%;
    --font-style-basis : 0.85vw;
    
}


.canvas-container{
    display: flex;
    position:relative;
    flex: 1 0 40%;
    max-width: 40%;
    padding-top: 40%;
    border-radius: 2.6%;
    box-shadow: 0 0 4px rgba(255,255,255,0.2);
    background-color:  #28313b;  
}

.canvas-container-inner{
    position: absolute;
    top:0;
    left :0;
    right: 0;
    bottom: 0;
    padding: 5%;
    background-color:  #28313b;  
    border-radius: 2.6%;


}

.disp-goat,.disp-tiger{
    position: relative;
    width: 100%;
    padding: 50%;
    box-shadow: 0 0 4px rgba(255,255,255,0.2);
    border-left: none;
    border-top-right-radius: 5%;
    
    background-color: rgb(13, 179, 185);
    background-image: url('../../assets/GOAT.png');
    background-position: center;
    background-size: cover;
    transition: 0.15s ease-in; 
    z-index: 2;
}

.disp-tiger{
    background-image: url('../../assets/TIGER.png');
}
.disp-goat{
    background-image: url('../../assets/GOAT.png');
}
.turn{
    position : absolute;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    left: 100%;
    top:0;

    width: 12.5%;
    margin-top: 4%;
    /* margin-bottom: 5%; */
    transition : transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    text-align: center;
}

.turn .title-name{
    color: white;
    margin: 0px;
    font-size: calc(1.7* var(--font-style-basis));
}

.give-up-btn{
    height: 5.2%;
    width: 100% !important;
    font-size: calc(1.1* var(--font-style-basis)) !important;
    border: 
}


.turn.hide{
    transform: translateX(calc(-7 * var(--factor1)));
    z-index: -1;
}
.turn.unveal{
    transform: translateX(0%);
    z-index: -1;
}
.clickable{
    z-index: 1 !important;
}



.Node{
    position: absolute;
    background-color: #20272f;
    height: 7.9%;
    width: 7.9%;
    transform: translate(-25%,-25%);
    border-radius: 50%;
    /* paths have zIndex 0 and nodes have 1 so that nodes appear above paths */
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
   
}

.Node.pointer-cursor{
    cursor: pointer;  
}
.Node.pointer-cursor1{
    z-index: 2;
    opacity: 0;
    cursor: pointer;  
}

/* cubic-bezier(.26,.45,.52,.77); */
.tiger{
    will-change: margin;
    position: absolute;
    transition: margin 0.17s ease-in-out;
    background-color: none;
    background-image: url('../../assets/TIGER.png');
    background-position: center;
    background-size: cover;
    width: 14.5%;
    height: 14.5%;
    color: white;
    transform: translate(-37%,-40%);
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}
.goat{
    will-change: margin;
    position: absolute;
    transition: margin 0.17s ease-in-out;
    background-color: none;
    background-image: url('../../assets/GOAT.png');
    background-position: center;
    background-size: cover;
    width: 14.5%;
    height: 14.5%;
    color: white;
    transform: translate(-37%,-40%);
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.Path{
    position: absolute;
    height: 2.6%;
    width: 2.6%;
    background-color: #20272f;
    box-shadow: 0 0 4px rgba(255,255,255,0.2);
    
}

/* positioning each node  and also ajdancet paths :( */
.Node-0,.tiger-0,
.Path-0-1,.Path-0-5,.Path-0-6{
    
    margin-top: calc(0  * var(--factor1)) ;
    margin-left: calc(0* var(--factor1));
}
.Path-0-6{ /* \\ slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc(0.5 * var(--factor1));
    margin-top: calc( -0.09* var(--factor1));
    transform: rotateZ(-45deg);
    
}


.Node-1,
.Path-1-2,.Path-1-6{
    margin-top: calc(0  * var(--factor1));
    margin-left: calc(1 * var(--factor1));
}


.Node-2,
.Path-2-3,.Path-2-7,.Path-2-6,.Path-2-8{
    margin-top: calc(0 * var(--factor1));
    margin-left: calc(2* var(--factor1));
}
.Path-2-8{ /* \\ slant */
    
    height: calc(1.3 * var(--factor1));
    margin-left: calc(2*var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( -0.09* var(--factor1));
    transform: rotateZ(-45deg);   
}
.Path-2-6{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc(var(--factor1) + 0.58 * var(--factor1));
    margin-top: calc( -0.11* var(--factor1));
    transform: rotateZ(45deg);   
}



.Node-3,
.Path-3-4,.Path-3-8{
    margin-top: calc(0 * var(--factor1));
    margin-left: calc(3* var(--factor1));
}



.Node-4,.tiger-4,
.Path-4-9,.Path-4-8
{
    margin-top: calc(0 * var(--factor1));
    margin-left: calc(4* var(--factor1));
}
.Path-4-8{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc(var(--factor1)*3 + 0.58 * var(--factor1));
    margin-top: calc( -0.11* var(--factor1));
    transform: rotateZ(45deg);   
}



.Node-5,
.Path-5-6,.Path-5-10{
    margin-top: calc(1  * var(--factor1)) ;
    margin-left: calc(0* var(--factor1));
}


.Node-6,
.Path-6-7,.Path-6-10,.Path-6-11,.Path-6-12{
    margin-top: calc(1  * var(--factor1));
    margin-left: calc(1 * var(--factor1));
}
.Path-6-10{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( 0.58 * var(--factor1));
    margin-top: calc((1 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}
.Path-6-12{ /* \\ slant */
    height: calc(1.3 * var(--factor1));
    margin-left: calc(1*var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (1 - 0.09)* var(--factor1));
    transform: rotateZ(-45deg);   
}


.Node-7,
.Path-7-8,.Path-7-12{
    margin-top: calc(1 * var(--factor1));
    margin-left: calc(2* var(--factor1));
}


.Node-8,
.Path-8-9,.Path-8-13,.Path-8-14,.Path-8-12{
    margin-top: calc(1 * var(--factor1));
    margin-left: calc(3* var(--factor1));
}
.Path-8-12{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( var(--factor1)*2 + 0.58 * var(--factor1));
    margin-top: calc((1 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}
.Path-8-14{ /* \\ slant */
    height: calc(1.3 * var(--factor1));
    margin-left: calc(3 *var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (1 - 0.09)* var(--factor1)); 
    transform: rotateZ(-45deg);   
}


.Node-9,
.Path-9-14{
    margin-top: calc(1 * var(--factor1));
    margin-left: calc(4* var(--factor1));
}

  
.Node-10,
.Path-10-11,.Path-10-15,.Path-10-16{
    margin-top: calc(2  * var(--factor1)) ;
    margin-left: calc(0* var(--factor1));
}
.Path-10-16{ /* \\ slant */
    height: calc(1.3 * var(--factor1));
    margin-left: calc(0 *var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (2 - 0.09)* var(--factor1)); 
    transform: rotateZ(-45deg);   
}


.Node-11,
.Path-11-12,.Path-11-16{
    margin-top: calc(2  * var(--factor1));
    margin-left: calc(1 * var(--factor1));
}



.Node-12,
.Path-12-16,.Path-12-13,.Path-12-17,.Path-12-18{
    margin-top: calc(2 * var(--factor1));
    margin-left: calc(2* var(--factor1));
}
.Path-12-16{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( var(--factor1)*1 + 0.58 * var(--factor1));
    margin-top: calc((2 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}
.Path-12-18{ /* \\ slant */
    
    height: calc(1.3 * var(--factor1));
    margin-left: calc(2 *var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (2 - 0.09)* var(--factor1)); 
    transform: rotateZ(-45deg);   
}


.Node-13,
.Path-13-14,.Path-13-18{
    margin-top: calc(2 * var(--factor1));
    margin-left: calc(3* var(--factor1));
}


.Node-14,
.Path-14-19,.Path-14-18{
    margin-top: calc(2 * var(--factor1));
    margin-left: calc(4* var(--factor1));
}
.Path-14-18{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( var(--factor1)*3 + 0.58 * var(--factor1));
    margin-top: calc((2 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}


.Node-15,
.Path-15-16,.Path-15-20{
    margin-top: calc(3  * var(--factor1)) ;
    margin-left: calc(0* var(--factor1));
}


.Node-16,
.Path-16-17,.Path-16-21,.Path-16-20,.Path-16-22{
    margin-top: calc(3  * var(--factor1));
    margin-left: calc(1 * var(--factor1));
}
.Path-16-20{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( var(--factor1)*0 + 0.58 * var(--factor1));
    margin-top: calc((3 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}
.Path-16-22{ /* \\ slant */
    
    height: calc(1.3 * var(--factor1));
    margin-left: calc(1 *var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (3 - 0.09)* var(--factor1)); 
    transform: rotateZ(-45deg);   
}


.Node-17,
.Path-17-18,.Path-17-22{
    margin-top: calc(3 * var(--factor1));
    margin-left: calc(2* var(--factor1));
}


.Node-18,
.Path-18-19,.Path-18-23,.Path-18-22,.Path-18-24{
    margin-top: calc(3 * var(--factor1));
    margin-left: calc(3* var(--factor1));
}
.Path-18-22{    /* //  slant*/
    height: calc(1.3 * var(--factor1));
    margin-left: calc( var(--factor1)*2 + 0.58 * var(--factor1));
    margin-top: calc((3 - 0.11)*var(--factor1));
    transform: rotateZ(45deg);   
}
.Path-18-24{ /* \\ slant */
    
    height: calc(1.3 * var(--factor1));
    margin-left: calc(3 *var(--factor1) + 0.5 * var(--factor1));
    margin-top: calc( (3 - 0.09)* var(--factor1)); 
    transform: rotateZ(-45deg);   
}


.Node-19,
.Path-19-24{
    margin-top: calc(3 * var(--factor1));
    margin-left: calc(4* var(--factor1));
}



.Node-20,.tiger-20,
.Path-20-21{
    margin-top: calc(4  * var(--factor1)) ;
    margin-left: calc(0* var(--factor1));
}


.Node-21,
.Path-21-22{
    margin-top: calc(4  * var(--factor1));
    margin-left: calc(1 * var(--factor1));
}


.Node-22,
.Path-22-23{
    margin-top: calc(4 * var(--factor1));
    margin-left: calc(2* var(--factor1));
}


.Node-23,
.Path-23-24{
    margin-top: calc(4 * var(--factor1));
    margin-left: calc(3* var(--factor1));
}


.Node-24,.tiger-24{
    margin-top: calc(4 * var(--factor1));
    margin-left: calc(4* var(--factor1));
}


/* vertical paths */
.Path-0-5,.Path-5-10,.Path-10-15,.Path-15-20,
.Path-1-6,.Path-6-11,.Path-11-16,.Path-16-21,
.Path-2-7,.Path-7-12,.Path-12-17,.Path-17-22,
.Path-3-8,.Path-8-13,.Path-13-18,.Path-18-23,
.Path-4-9,.Path-9-14,.Path-14-19,.Path-19-24{ 
    transform: translateX(30%);
    height:var(--factor1);
}

/* horizontal paths */
.Path-0-1,.Path-1-2,.Path-2-3,.Path-3-4,
.Path-5-6,.Path-6-7,.Path-7-8,.Path-8-9,
.Path-10-11,.Path-11-12,.Path-12-13,.Path-13-14,
.Path-15-16,.Path-16-17,.Path-17-18,.Path-18-19,
.Path-20-21,.Path-21-22,.Path-22-23,.Path-23-24{ 
    transform: translateY(30%);
    width: var(--factor1);
    
}

/* .Node.highlight-danger,.Node.highlight-safe{
    transition : box-shadow 0.1s ease-in;
} */


.highlight-safe{
    box-shadow:  0px 0px 0.7vmin 0.5vmin rgb(13, 179, 185) !important ;
    /* transition: background-color 0.1s ease-in; */
    box-shadow: none; 
}

.highlight-danger{
    box-shadow: 0px 0px 0.7vmin 0.5vmin rgb(249, 60, 73) !important ; 
    opacity: 1 !important;
    z-index: 1 !important;
}

/* overlay section */

.baghchal-overlay{
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 1rem;
    color: white;
    background-color: rgb(0,0,0,0.8);
    z-index: -1;
    border: 1px solid black;
    border-radius: 2.6%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay-visible{
    align-items: center;
    z-index: 3;
}

/* to slide the winner/draw images to right with animation 
/* It is of no use now that I used scale-in-center */
.baghchal-overlay > div{
    margin-bottom: calc(1 * var(--font-style-basis));
    transform: translateX(-5rem);
    opacity: 0.3;
}
.baghchal-overlay.overlay-visible > *{
    transform: translateX(0); 
    opacity: 1;      
}

.winner{
    will-change: transform;
    margin-top: 17%;
    width: 35%;
    height: 35%;
    background-position: center;
    background-size: cover;  
    transition : 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.winner-text{
    font-size: calc(3 * var(--font-style-basis));
    transition : 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: -moz-fit-content;
    width: fit-content;
}

.winner-Goat{
    background-image: url('../../assets/GOAT.png');
}
.winner-Tiger{
    background-image: url('../../assets/TIGER.png');
}

.play-again-btn,.new-game-btn,.vs-user-btn,.vs-comp-btn,.give-up-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
  }
  
  .play-again-btn,.new-game-btn,.vs-user-btn,.vs-comp-btn,.give-up-btn{
    border-color: transparent;
    background-color: #fff;
    color: #000;
    border: calc(0.5 * var(--font-style-basis)) solid transparent;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
  }
    
  .play-again-btn:hover,
  .new-game-btn:hover,
  .vs-user-btn:hover,
  .vs-comp-btn:hover, 
  .give-up-btn:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
    box-shadow:  0px 0px 2px 1px white !important ;
  }

  .play-again-btn{
    min-width: calc(12 * var(--font-style-basis)) !important;
    height: calc(3 * var(--font-style-basis)) !important;
    font-size: calc(1.4 * var(--font-style-basis));
  }

  .give-up-btn.no-click1{
    pointer-events: none;
  }

  .draw-container{
    display: flex;
    margin-top: 17%;
    width: 100%;
    height: 35%;
    justify-content: center;
  }
  .draw-container .winner{
    margin-top: 0;
    height: auto;
  }



.wobble-hor-top {
	-webkit-animation: wobble-hor-top 1s both 2;
	        animation: wobble-hor-top 1s both 2;
}

@-webkit-keyframes wobble-hor-top {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(6deg);
              transform: translateX(-30px) rotate(6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(-6deg);
              transform: translateX(15px) rotate(-6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(3.6deg);
              transform: translateX(-15px) rotate(3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(-2.4deg);
              transform: translateX(9px) rotate(-2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(1.2deg);
              transform: translateX(-6px) rotate(1.2deg);
    }
  }
  @keyframes wobble-hor-top {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(6deg);
              transform: translateX(-30px) rotate(6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(-6deg);
              transform: translateX(15px) rotate(-6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(3.6deg);
              transform: translateX(-15px) rotate(3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(-2.4deg);
              transform: translateX(9px) rotate(-2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(1.2deg);
              transform: translateX(-6px) rotate(1.2deg);
    }
  }