.rules-status{
    margin-left: 1%;
    flex: 0 0 20%;
    width: 20%;
    /* border: 1px solid white; */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(1.5 * var(--font-style-basis));
}


.rules-status svg{
    width: calc(3.5 * var(--font-style-basis));
    height: calc(3.5 * var(--font-style-basis));
}
.user-options svg{
    width: calc(1.5 * var(--font-style-basis));
    height: calc(1.5 * var(--font-style-basis));
}

.rules-btn{
    display: flex;
    flex-direction: column;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
}
.rules{
    width: calc(3 * var(--font-style-basis));
    height: calc(3 * var(--font-style-basis));
    cursor: pointer;
    text-align: center;
}
.rules-btn .title-name{
    color: white !important;
    font-size: calc(1.7 * var(--font-style-basis)) !important;
}
.status-form-container{
    width: 95%;
    padding-top: 109%;
    color: white;
    text-align: center;
    perspective: 1000px;
    background-color: transparent; 
}
.status-form-container-inner{
    will-change: transform;
    box-shadow: 0 0 4px rgba(255,255,255,0.2);
    border-radius: 5%;
    background-color: #28313b;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;


}

.status-form-container.show-score .status-form-container-inner{
    transform: rotateY(180deg);
}
/* for safari */
.status-form-container.show-score .user-options{
    visibility: hidden;
}
.status-form-container.show-score .game-status{
    visibility: visible;
}

.user-options , .game-status{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.user-options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap : calc(var(--font-style-basis));
}

.game-status{
    width: 100%;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: row;
    visibility: hidden;
}
.status-main{
    display: flex;
    flex-direction: column;
    width : 60%;
    align-items: center;
    justify-content: space-evenly;
}
.status{
    display: flex;
    flex-direction: column;
}
.status .title-name{
    font-size: calc(1.7 * var(--font-style-basis));

}

.status .display-numbers{
    font-size: calc(1.5 * var(--font-style-basis));
}



.move-history{
    width: 40%;
    border-left: 1px solid rgb(183, 182, 182);
    padding: calc(1 * var(--font-style-basis)) 0;
}
.move-history .title-name{
    font-size: calc(1.7 * var(--font-style-basis));
}
.history{
    font-size: calc(1.1 * var(--font-style-basis));
}
.histories{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    height: 85%;
    margin-top: calc(0.5*var(--font-style-basis));
    line-height: calc(1.5 * var(--font-style-basis));
}


.histories::-webkit-scrollbar {
    width: calc(0.8 * var(--font-style-basis));
    height: 1em;
}
  
.histories::-webkit-scrollbar-track {
background: #dddddd;
border-radius: 100vw;
margin-left: 0.5rem;
}
  
.histories::-webkit-scrollbar-thumb {
background: rgb(13, 179, 185);
border: 2px solid #dddddd;
border-radius: 100vw;
}
  
.histories::-webkit-scrollbar-thumb:hover {
background: rgb(3, 159, 165);
}


.title-name{
    font-size: calc(1.5 * var(--font-style-basis));
}
.display-numbers{
    font-size: calc(1.4 * var(--font-style-basis));
    
}



span.title-name{
    margin: 0 calc(0.5 * var(--font-style-basis));
}


.click-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.click-btn:nth-child(1){
    transform: translateX(-4px);
}

.click-btn .title-name{
    font-size: calc(1.7 * var(--font-style-basis));
}
.click-btn > div{
    position: relative;
    border : 1px solid white;
    width: calc(2.5 * var(--font-style-basis));
    height: calc(2.5 * var(--font-style-basis));
    border-radius: 100%;
    margin-right: calc(1 * var(--font-style-basis));
    margin-bottom: calc(0.6 * var(--font-style-basis));
}

.vsTiger,.vsGoat{
    margin-left: calc(2 * var(--font-style-basis));
}

.no-click{
    color: #444444;
    border-color: #444444 !important;
    pointer-events : none;
}



.new-game-btn{
    min-width: calc(12 * var(--font-style-basis)) !important;
    height: calc(3 * var(--font-style-basis)) !important;
    font-size: calc(1.4 * var(--font-style-basis));;
}

  ul {
    list-style : none;
  }
  
  
  li > label{
    margin-left: calc(1 * var(--font-style-basis));
    color: white;
    font-size: calc(1.2 * var(--font-style-basis));
  }
  
  .active-btn{
    box-shadow:  0px 0px 0.7vmin 0.5vmin rgb(13, 179, 185) !important ;
    border-color : rgb(13, 179, 185) !important;
    
  }
  
  .hidden{
      display: none !important;
  }