@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
*,*::after,*::before{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Abel', sans-serif;
  /* background-color: rgba(100,100,0,0.2) !important;  */
}

#root{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 2rem;
}

.baghchal-app{ 
  width: 100%;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
}
@media screen and (max-width : 1200px) {
  .canvas-container{
    flex : 1 0 30rem !important;
    max-width: 30rem !important;
    padding-top: 30rem !important;
  }
  .rules-status{
    flex : 0 0 15rem !important;
    width: 15rem !important;
  }
  :root{
    --font-style-basis : 0.8vw !important;
  }
  
}

@media  screen and (max-width : 992px) {
  .canvas-container{
    flex : 1 0 55% !important;
    max-width: 55% !important;
    padding-top: 55% !important;
  }
  .rules-status{
    flex: 0 0 27% !important;
    width: 27% !important;
  }
  :root{
    --font-style-basis : 1.2vw !important;
  }
}
@media  screen and (max-width : 688px) {
  .canvas-container{
    flex : 1 0 60% !important;
    max-width: 60% !important;
    padding-top: 60% !important;
  }
  .rules-status{
    flex: 0 0 30% !important;
    width: 30% !important;
  }
  :root{
    --font-style-basis : 1vw !important;
  }
}
@media  screen and (max-width : 560px) {
  .canvas-container{
    flex : 1 0 65% !important;
    max-width: 65% !important;
    padding-top: 65% !important;
  }
  .rules-status{
    flex: 0 0 32% !important;
    width: 32% !important;
  }
  :root{
    --font-style-basis : 1vw !important;
  }
}
@media  screen and (max-width : 480px) {
  .canvas-container{
    flex : 1 0 95% !important;
    max-width: 95% !important;
    padding-top: 95% !important;
    transform: rotateZ(90deg) ;
    z-index: 1;
  }
  .rules-status{
    flex : 1 0 47% !important;
    max-width: 47% !important;
    transform: rotateZ(90deg)translate(calc(0.5*var(--font-style-basis)),calc(3.5*var(--font-style-basis)));
  }
  .rules-btn{
    position: absolute;
    transform: translateY(calc(-19*var(--font-style-basis)));
  }
  :root{
    --font-style-basis : 1.8vw !important;
  }
  .user-options{
    gap: calc(1 * var(--font-style-basis)) !important;
  }
  
}

@media screen and (max-width:350px) {
  .status-main,.histories,.user-options{
    overflow: hidden;
  }
  :root{
    --font-size-basis : 0.8vw !important;
  }
  .user-options{
    gap: calc(1 * var(--font-style-basis)) !important;
  }
  
}

